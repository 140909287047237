.app.dark {
  background-color: #212529;
  color: #9c9c9c;
}
.app.dark .chartGrid {
  stroke: rgba(228, 228, 228, 0.219);
}
.app.dark .table {
  background-color: #212529;
}
.app.dark .table .tableCell {
  color: gray;
}
.app.dark .navbar {
  color: #999;
  border-color: #333;
}
.app.dark .navbar .search {
  border-color: gray;
}
.app.dark .sidebar {
  background-color: #212529;
  border-color: #333;
}
.app.dark .sidebar .top .logo {
  color: #999;
}
.app.dark .sidebar hr {
  border-color: #333;
}
.app.dark .sidebar ul li:hover {
  background-color: #333;
}
.app.dark .sidebar ul li .icon {
  color: #999;
}
.app.dark .datatable .datagrid {
  color: gray;
  border: none;
}
.app.dark .datatable .datagrid .viewButton, .app.dark .datatable .datagrid .deleteButton, .app.dark .datatable .datagrid .cellWithStatus {
  color: gray;
  border: none;
}
.app.dark input {
  background-color: transparent;
}


.app.dark table {
  background-color: #111 !important;

  --bs-table-color: #fff !important;
--bs-table-bg: #212529 !important;
--bs-table-border-color: #373b3e !important;
--bs-table-striped-bg: #2c3034 !important;
--bs-table-striped-color: #fff !important;
--bs-table-active-bg: #373b3e !important;
--bs-table-active-color: #fff !important;
--bs-table-hover-bg: #323539 !important;
--bs-table-hover-color: #fff !important;
color: var(--bs-table-color) !important;
border-color: var(--bs-table-border-color) !important;
}

.app.dark .modal-content {
  background-color: #111 !important;
color: var(--bs-table-color) !important;
border-color: var(--bs-table-border-color) !important;
}