.app {
    display: flex;
  }
.container-app {
    flex: 6;
  }

  *{
    font-size: 13px;
  }

  p{
    margin: 0;
  }


  .btn-ios {
    padding: 1rem;
    text-align: center;
    color: #0d6efd;
    background: white;
    border:0;
    font-weight: bold;
  }
  
  .btn-ios:hover,.btn-ios:focus {
    /*background: #e9ecef;*/
    background: #F6F9FC;
    cursor: pointer;
  }
  .btn-ios:active{
    background: #e4e7eb;
    cursor: pointer;
  }
  
/*ELIMINAMOS EL BORDE DE LOS INPUTS DE BOOTSTRAP*/
select:hover,
select:active,
select:focus,
textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}
hr{
  margin:0;
  color: inherit;
  border-width: 1px;
}
.pointer{
  cursor: pointer;
}
.pointer-cell{
  cursor: cell;
}


.help-cursor{
  cursor: help;
}

.dark td{
  background: #191b1e;

}

.dark .fc-theme-standard td, .dark .fc-theme-standard th {
  border: 1px solid gray;
}