.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login{
    background: #d2d6de;
    height: 100vh;
    
}

.login-box{
    border-top: 3px solid #007bff;
}

a{
    text-decoration: none;
}

b{
    font-weight: bolder;
}

.h1, h1 {
    font-size: 2.5rem;
  }

  .login-box-msg{
      font-size: 14px;
  }